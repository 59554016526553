// this is just temporary, 
// access control should be from backend

let accessControl = {
  superadmin: '*',
  admin: {
    catalogue: '0xFF',
    dashboard: '0xFF',
    monitoring: '0xFF',
    alert: '0xFF',
    tracks: '0xFF',
    reports: '0xFF',
    geofence: '0xFF',
    drivers: '0xFF',
    dashboard_rental: '0xFF',
    vehicles: '0xFF',
    satellite: '0xFF',
    devices: '0xFF',
    users: '0xFF',
    privilege: '0xFF',
    generator: '0xFF',
    teltonika: '0xFF',
    change_password: '0xFF',
    setting: '0x31'
  },
  operator: {
    dashboard: '0x01',
    monitoring: '0x01',
    alert: '0x01',
    tracks: '0x01',
    reports: '0x01',
    geofence: '0x01',
    drivers: '0x01',
    dashboard_rental: '0x01',
    vehicles: '0x01',
    satellite: '0x01',
    generator: '0x01',
    teltonika: '0x01',
    setting: '0x31'
  },
  guest: {
    catalogue_user: '0x01',
    generator: '0x01',
    teltonika: '0x01',
    setting: '0x31'
  },
}

export const Perms = {
  READ: 0x01,
  CREATE: 0x02,
  UPDATE: 0x04,
  DELETE: 0x08,

  // customs
  CHANGE_PASSWORD: 0x11,
  CHANGE_THEME: 0x21
}

const permissions = 'eyJzdXBlcmFkbWluIjoiKiIsImFkbWluIjp7ImNhdGFsb2d1ZSI6IjB4RkYiLCJkYXNoYm9hcmQiOiIweEZGIiwibW9uaXRvcmluZyI6IjB4RkYiLCJhbGVydCI6IjB4RkYiLCJ0cmFja3MiOiIweEZGIiwicmVwb3J0cyI6IjB4RkYiLCJnZW9mZW5jZSI6IjB4RkYiLCJkcml2ZXJzIjoiMHhGRiIsImRhc2hib2FyZF9yZW50YWwiOiIweEZGIiwidmVoaWNsZXMiOiIweEZGIiwiZGV2aWNlcyI6IjB4RkYiLCJ1c2VycyI6IjB4RkYiLCJwcml2aWxlZ2UiOiIweEZGIiwiZ2VuZXJhdG9yIjoiMHhGRiIsImNoYW5nZV9wYXNzd29yZCI6IjB4RkYiLCJzZXR0aW5nIjoiMHgzMSJ9LCJvcGVyYXRvciI6eyJkYXNoYm9hcmQiOiIweDAxIiwibW9uaXRvcmluZyI6IjB4MDEiLCJhbGVydCI6IjB4MDEiLCJ0cmFja3MiOiIweEZGIiwicmVwb3J0cyI6IjB4MDEiLCJnZW9mZW5jZSI6IjB4RkYiLCJkcml2ZXJzIjoiMHgwMSIsImRhc2hib2FyZF9yZW50YWwiOiIweDAxIiwidmVoaWNsZXMiOiIweDAxIiwiZ2VuZXJhdG9yIjoiMHgwMSIsInNldHRpbmciOiIweDMxIn0sImd1ZXN0Ijp7ImNhdGFsb2d1ZV91c2VyIjoiMHgwMSIsImdlbmVyYXRvciI6IjB4MDEiLHRlbHRvbmlrYSI6IjB4MDEiLCJzZXR0aW5nIjoiMHgzMSJ9fQ=='
// const accessControl = JSON.parse(Buffer.from(permissions, 'base64').toString())



export const IsFeatureActive = (feature) => {
  const excludes = ['analytic_dashboard']

  let currentFeature = localStorage.getItem('features') || { list: {} }
  try { currentFeature = JSON.parse(currentFeature) } catch (e) {
    currentFeature = { list: {} }
  }


  if (excludes.includes(feature))
    if (typeof currentFeature.list[feature] === "undefined") return false

  if (currentFeature.list && typeof currentFeature.list[feature] === "undefined") return true
  return Number(currentFeature.list[feature]) ? true : false
}



export const CanAccess = (role, mod, access) => {
  if (!IsFeatureActive(mod)) return false
  if (typeof accessControl[role] === "string"
    && accessControl[role] === "*"
    && !['catalogue_user', 'dashboard_rental'].includes(mod))
    return true

  if (!accessControl[role] || !accessControl[role][mod] || !access) return false

  let permission = parseInt(accessControl[role][mod].replace('0x', ''), 16)
  console.log(permission)
  console.log(access)

  return permission & access
}
