
export const btOptions = () => {
  return {
      ajax: null,
      search: true,
      searchSelector: null,
      showSearchButton: true,
      sortable: true,
      pagination: true,
      paginationHAlign: "center",
      paginationDetailHAlign: "right",
      paginationParts: ["pageInfo", "pageList", "pageNumbers"],
      classes: ["table", "table-hover"],
      loadingFontSize: 16,
      sidePagination: "server"
  }
}

export const queryParams = (params) => {
  return {
    start: params.offset,
    limit: params.limit,
    search: params.search,
    sort_by: params.sort || 'series',
    sort: params.order || 'asc',
  }
}


export const queryTableNormalize = (params) => {
  params = params ? (params.data || {}) : {}

  let page = params.offset/params.limit || 1

  let sort = params.sort || 'created_at'
  let order = params.order || 'desc'
  
  return {
    limit: params.limit || 10,
    page,
    q: params.search,
    sort: `${sort}.${order}`
  }
}

export const responseHandler = (res) => {
  return {
    total: res.total_rows
  }
}